export default {
    tipoCalculo: [
        { value: 1, text: 'Socio' },
        { value: 2, text: 'Sucursal' },
        { value: 3, text: 'Empleado o individual' },
        { value: 4, text: 'Resultado de tienda' },
        { value: 5, text: 'Descendientes' },
    ],
    setParam(value) {
        return JSON.stringify(value).replace(/:/g, "=").replace('{', '').replace(/"/g, '').replace(/,/g, '&').replace('}', '')
    },
    getName(list, id, columnId, columnName) {
        try {
            var item = list.find(c => c[columnId] === id)
            return item === '' ? '' : item[columnName]
        } catch {
            return ''
        }
    },
    sumBy(objectArray, property, value) {
        var data = objectArray.map(c => [c[property], c[value]])
        var keys = [...new Set(data.map(c => c[0]))]

        var result = [];
        keys.forEach(item => {
            var values = data.filter(c => c[0] == item);
            var sum = values.reduce((a, b) => a + (b[1] || 0), 0);
            var _item = {};
            _item[property] = item;
            _item[value] = sum;
            result.push(_item);
        });

        return result;
    },
    maxBy(objectArray, property, value) {
        // var data = objectArray.map(c => [c[property], c[value]])
        var values = objectArray.map(c => c[value])

        var max = values.reduce((a, b) => Number(a) > Number(b) ? a : b, 0);
   
        return max;
    },
    aveBy(objectArray, property, value) {
        // var data = objectArray.map(c => [c[property], c[value]])
        var values = objectArray.map(c => c[value])

        // var result = [];
        var ave = values.reduce((a, b) => a + b, 0);
        // var _item = {};
        // _item[property] = item;
        // _item[value] = ave / objectArray.length;
        // result.push(_item);

        return ave / objectArray.length;
    },
    abbreviateName(value) {
        var splited = value.split(" ");
        return splited.length <= 2 ? value : splited.reduce((a, b, c) => a + (c % 2 == 0 ? b + ' ' : ''), '');
    },
    capitalCase(value) {
        var splited = value.toLowerCase().split(" ");
        return splited.reduce((a, b) => a + (b.charAt(0).toUpperCase() + b.slice(1) + ' '), '');
    },
    replacePuesto(value) {
        return value.replace('SupGer', '')
            .replace('Representantes', '')
            .replace('Representante', '');
    }
}